<template>
  <v-form
    :key="reference.id"
    ref="referenceForm"
    v-model="valid"
    @submit.prevent="createReference"
  >
    <v-row class="pa-3">
      <v-col
        cols="12"
        class="mt-2 pb-0"
      >
        <v-select
          v-model="referenceCode"
          :items="['PMID','DOI']"
          label="Type code"
          :readonly="readonlyCode"
          dense
        />
        <v-text-field
          v-model="referenceName"
          label="Code"
          :loading="loadingCode"
          :readonly="readonly"
        />
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
  export default {
    components: {
    },
    props: {
      
      open: Boolean,
      readonly: {
        type: Boolean,
        default: false,
      },
      info: Boolean,
      code: {
        type: String,
        default: null,
      },
      codeType: {
        type: String,
        default: 'PMID',
      },
      loadingCode: {
        type: Boolean,
        default: false,
      },
      reference: {
        type: Object,
        default: () => ({}),
      },
      
    },
    data () {
      return {
        referenceName: this.code,
        referenceCode: this.codeType,
        readonlyCode: this.readonly,
        loading: false,
        valid: false,
      }
    },
    watch:{
      loading () {
        this.$emit('loading', this.loading)
      },
      loadingCode (){
        this.referenceName = this.code;
      },
      codeType(){
        this.referenceCode = this.codeType.toUpperCase();
      },
      readonly (){
        this.readonlyCode = this.readonly;
      },
    },
    methods: {
      createReference () {
        this.loading = true;
        this.axios.post('admin/references', 
          { 
            code_type: this.referenceCode,
            code: this.referenceName,
          },
        )
          .then((response) => {
            this.referenceName = null;
            this.$emit('update-reference', response.data);
            this.$emit('reference-created');
            this.$toast.success('Reference created');
          })
          .catch(error => {
            this.$toast.error(error.response.data.code ? error.response.data.code[0] : error)
          })
          .finally(() => {
            this.loading = false
          });
      },
    },
  }
</script>